import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Center,
  Pressable,
  Text,
  Hidden,
  PresenceTransition,
} from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import AdministrationHTML from "./components/Inter Se Seniority List Administration";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import OrganizationChartImage from "@assets/images/menu/organization-chart.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import { Carousel } from "antd";
import AdministrationLink from "./components/administration_link/administration_link";
import AdministrativeSetupTable from "./components/administration_setup/administrative_setup";
import InterSeSeniorityListTable from "./components/inter_se_seniority_list_administration/inter_se_seniority_list_administration";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { display } from "styled-system";
import { startCase } from "lodash";

const Administration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showcm, setshowcm] = useState(false);
  const showcardcm = () => {
    setshowcm(true);
  };
  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={t("administration")}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",

              md: "row",
            }}
          >
            <Hidden only={["xl", "lg", "md"]}>
              <Pressable onPress={showcardcm}>
                <Box
                  style={{
                    backgroundColor: "#EEF5D8",
                    height: "40px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Text fontSize={"19px"} marginLeft={"100px"}>
                    Organization{"               "}
                    <Box>
                      <AiOutlinePlusCircle
                        size={"20px"}
                        style={{
                          position: "relative",
                          top: "5px",
                        }}
                      />
                    </Box>
                  </Text>
                </Box>
                {showcm && (
                  <PresenceTransition
                    visible={showcm}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: 250,
                      },
                    }}
                  >
                    <Box>
                      <SecondarySiderMenu type="organization" />
                    </Box>
                  </PresenceTransition>
                )}
              </Pressable>
            </Hidden>
            <Hidden only={["xs", "sm"]}>
              <Box w={["50%", "100%", "30%"]} h="100%" alignItems="center">
                <SecondarySiderMenu type="organization" />
              </Box>
            </Hidden>
            <Box
              w={["100%", "100%", "65%"]}
              padding={["10px", "20px", "0px"]}
              h="100%"
            >
              <VStack>
                <Box h={["60%", "60%", "30%"]}>
                  {/* <AdministrationLink /> */}
                </Box>
                <Box h="70%">
                  {/* <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: 'bold' }}>  ORGANIZATION CHART AS ON 20.12.2024</Text> */}

                  <Text fontSize="20px" fontWeight="bolder" alignSelf='center'  color={"#3c5649"}>
                    ORGANIZATION CHART AS ON 20-12-2024
                              </Text>
                  <img
                    src={OrganizationChartImage}
                    alt="organization-chart-cover"
                  />
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
        <Box>
          <HStack>
            <Box w={["3%", "3%", "30%"]} h="100%"></Box>
            <Box w={["95%", "95%", "65%"]} h="100%">
              <AdministrationHTML />
            </Box>
          </HStack>
        </Box>
        <HStack>
          <Box w={["0%", "0%", "30%"]} h="100%"></Box>
          <Box w={["100%", "100%", "65%"]} h="100%">
            <AdministrativeSetupTable />
          </Box>
        </HStack>
        <HStack>
          <Box w={["0%", "0%", "30%"]} h="100%"></Box>
          <Box w={["100%", "100%", "65%"]} h="100%">
            <Text>
              <br />
              Inter-se-seniority list of Forest Guards / Forest Guards with
              Driving Licence from 2002 to 2017 as per Hon'ble High Court Order
              dated 17.04.2018 in W.A. Nos.811, 812 and 877 of 2011 and W.A.
              (MD) No.1151 of 2017{" "}
            </Text>
            <InterSeSeniorityListTable />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
export default Administration;
